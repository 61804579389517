import { UrlParamsReplace } from "@services/urls/index";

export const GET_PRE_SIGNED_URL = (bucket, key, expiredAt) =>
    UrlParamsReplace('/files/pre-signed-url?bucket=:bucket&key=:key&expiredAt=:expiredAt', {
        bucket,
        key,
        expiredAt
    });


export const UPLOAD_FILE_URL = () => UrlParamsReplace('/files/upload');

export const UPLOAD_ADMIN_FILE_URL = () => UrlParamsReplace('/kra/file/upload');

export const GET_RENAME_FILE_URL = () => UrlParamsReplace('/files/rename');
export const GET_FILE_URL = (bucket, key) => UrlParamsReplace('/files?bucket=:bucket&key=:key', {
    bucket, key
});
