import {hasWindow,} from '@services/http.service';
import {GET_FILE_URL, GET_PRE_SIGNED_URL, GET_RENAME_FILE_URL} from '@services/urls/files';
import * as CsrHttpService from "@http/csr-http.service";
import * as SsrHttpService from "@http/ssr-http.service";

const getFile = async (Bucket, key, ctx = null) => {
    const url = GET_FILE_URL(Bucket, key);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

const getSignedUrl = async (Bucket, Key, ExpiresAt, ctx = null) => {
    const url = GET_PRE_SIGNED_URL(Bucket, Key, ExpiresAt);
    if (hasWindow()) {
        return CsrHttpService.getWithAuth(url);
    }
    return SsrHttpService.getWithAuth(url, ctx);
};

const renameFile = async (bucket, copySource, key, ctx = null) => {
    const url = GET_RENAME_FILE_URL();
    const body = {
        bucket: bucket,
        copySource: copySource,
        key: key,
    };
    if (hasWindow()) {
        return CsrHttpService.postWithAuth(url, body);
    }
    return SsrHttpService.postWithAuth(url, body, ctx);
};

export {getSignedUrl, renameFile, getFile};
