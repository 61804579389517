import Image from '@atoms/Image';
import { useRouter } from 'next/router';
import React from 'react';

const HomeNavbar = ({ isWhiteHeader = false, ...props }) => {
    const router = useRouter();
    return (
        <div className={`sticky top-0 z-30 justify-between ${props.isUserSurvey ? 'h-[75px] py-5 md:py-7' : 'items-center h-20 flex'} ${isWhiteHeader || props.isUserSurvey ? 'bg-basicWhite text-primary-500' : 'bg-primary-500 text-basicWhite'} ${isWhiteHeader ? 'navbar-sticky' : ''}`}>
            <div className={`${props.isUserSurvey ? 'max-w-screen-maxScreen px-8' : 'container'} mx-auto `}>
                <div className={`flex ${props.isUserSurvey ? 'md:justify-start' : 'justify-center'}  w-full`}>
                    {
                        props.isUserSurvey || isWhiteHeader ? <Image src="/images/logo.svg" alt="jiraaf-logo" className={props.isUserSurvey ? '' : 'w-[120px] xl:h-8 cursor-pointer'} onClick={props.isUserSurvey ? null : () => router.replace('/')} /> :
                            <Image src="/images/logo-white.svg" alt="jiraaf-logo" />
                    }
                </div>
            </div>
        </div >
    );
};

export default HomeNavbar;
